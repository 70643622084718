import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/libre-baskerville";
import "@fontsource/montserrat";

import { Footer, Navigation, Title } from "./index";
import Unordered from "~/components/List/Unordered";

const Body = (): JSX.Element => {
  return (
    <div className="bg-cbd-truth-light-blue text-cbd-truth-dark-blue font-light flex-grow p-10">
      <h2 className="mt-0 text-xl" style={{ fontFamily: "Libre Baskerville" }}>About CBD Truth</h2>
      <div className="relative flex flex-wrap md:flex-nowrap mt-5 p-3 md:p-10 border border-black bg-gray-50 text-gray-700 text-sm">
        <p className="md:w-1/4 lg:mb-28"><span className="font-bold text-lg">Susan Harris</span> is the executive director of CBD Truth. She holds both an MD and a PhD degree and has more than 20 years' experience conducting research on the effects of drugs on the brain and body. She has written books and articles about CBD that convey current scientific information about cannabis. She feels it is important for people to understand what CBD is and what the benefits and risks are of its use. </p>
        <p className="mt-5 md:mt-0 md:w-1/4 md:ml-4"><span className="font-bold text-lg">Shawn Wilson</span> is the lead writer and web content developer for CBD Truth. She has a background in biology and as a freelance writer.</p>
        <p className="mt-5 md:mt-0 md:w-1/4 md:ml-4"><span className="font-bold text-lg">Elena Garcia, MD,</span> reviews medical issues related to CBD use.</p>
        <p className="mt-5 md:mt-0 md:w-1/4 md:ml-4"><span className="font-bold text-lg">LeAnn Jones</span> is the office administrator for CBD Truth.</p>
        <div className="md:absolute flex flex-wrap flex-col mt-5 md:mt-0 items-center md:bottom-0 md:right-0 md:w-1/3 md:mb-12 md:mr-40">
          <StaticImage
            alt="CBD Truth logo."
            loading="eager"
            src="../../../../images/cbd-truth-logo.svg"
            layout="fixed"
            width={78}
            height={92}
          />
          <h1 className="text-2xl text-cbd-truth-dark-blue font-bold mt-5 md:mt-0 md:text-4xl" style={{ fontFamily: "Libre Baskerville" }}>CBD Truth</h1>
          <span className="mt-1 border border-cbd-truth-light-blue w-48 md:w-96" style={{ height: "1px" }} />
          <span className="mt-1 text-lg text-cbd-truth-blue font-medium text-center md:text-2xl">SEPARATE THE FACT FROM THE FICTION ABOUT CBD</span>
        </div>
      </div>
      <h2 className="mt-8 text-xl" style={{ fontFamily: "Libre Baskerville" }}>Advisory Board</h2>
      <p className="mt-5">CBD Truth consults with many professionals to verify the information about CBD. The advisory board members currently include the following people:</p>
      <div className="mt-5">
        <Unordered>
          <li><span className="font-semibold">Samuel Gomez, MD,</span> studies the effects of CBD and medical marijuana on the brain</li>
          <li><span className="font-semibold">Mary Hankins, Masters in Public Health,</span> works with communities and groups to spread information about CBD and medical marijuana use</li>
          <li><span className="font-semibold">Alan Bloom, RN,</span> is a registered nurse who works with people who have substance abuse problems</li>
          <li><span className="font-semibold">John Roberts, PhD,</span> is a neurobiologist who conducts research on how drugs of abuse affect the functioning of the brain</li>
          <li><span className="font-semibold">Krystal Stowe, MD,</span> has more than 12 years' experience in research on the effects of cannabis use</li>
        </Unordered>
      </div>
    </div>
  );
};

const CBDTruthPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Montserrat" }}>
      <Title />
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default CBDTruthPage;
